// ** Reducers Imports
import layout from "./layout"
import navbar from "./Reducers/navbar"
import user from "./Reducers/userRedux"
import authentication from "./Reducers/authentication"
import { combineReducers } from "redux";
import startSellingReducer from '../utility/api/services/StartSelling/StartSellingSlice';
import UserSlice from "../utility/api/services/UserPortal/UserSlice";
import validateUserReducer from '../utility/api/services/ValidateUser/ValidateUserSlice';

const rootReducer = combineReducers({ 
    navbar,
    layout,
    authentication,
    user,
    startSelling: startSellingReducer,
    userPortal:UserSlice,
    validateUser: validateUserReducer,
});

export default rootReducer;
