// BASE URL
const Maelano_URL = "https://maelano.info/api/v1/";

export const API_URLS = {

    // User Validations
    VALIDATE_EMAIL: `${Maelano_URL}auth/validate-user-email`,
    SEND_EMAIL_CODE: `${Maelano_URL}auth/email-code`,
    EMAIL_VERIFICATION: `${Maelano_URL}auth/verify-reset-code`,
    REGISTRATION_FORM: `${Maelano_URL}auth/register`,

    // landing page
    GET_ALL_CATEGORIES: `${Maelano_URL}landing-pages/categories`,
    GET_FILTER_DATA: `${Maelano_URL}landing-pages`,
    PEEK_CATEGORIES: `${Maelano_URL}landing-pages/peek-other-categories`,
    PRODUCTS_YOU_MAY_LIKE: `${Maelano_URL}landing-pages/products-you-may-like-category`,
    FETCH_RELEVANT_CATEGORIES: `${Maelano_URL}landing-pages/categories`,
    FETCH_FILTERED_PRODUCTS: `${Maelano_URL}landing-pages/products/category`,

    // Seller Program
    GET_START_SELLING_DATA: `${Maelano_URL}landing-pages/seller`,
    CONTACT_US_FORM: `${Maelano_URL}landing-pages/seller/contact-form`,
    CONTACT_US_FAQS: `${Maelano_URL}landing-pages/seller/contact-us-faqs`,
    CUSTOMER_SUPPORT_DATA: `${Maelano_URL}landing-pages/seller/customer-support`,


    // USER PORTAL
    // Dashboard
    GET_USER_DASHBOARD: `${Maelano_URL}users/dashboard`,
    // MY PROFILE - CHANGE PASSWORD
    UPDATE_USER_PASSWORD: `${Maelano_URL}users/update-password`,
    GET_USER_PROFILE: `${Maelano_URL}users/profile`,
    
    // Address Book
    GET_USER_ADDRESS: `${Maelano_URL}users/addresses`,
    REMOVE_USER_ADDRESS : `${Maelano_URL}users/delete-address`,
    SET_DEFAULT_USER_ADDRESS : `${Maelano_URL}users/set-default-delivery-address`,

    

    


}
